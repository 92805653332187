import React from "react"
import { localized } from "../../../../utils/localized"
import Image from "gatsby-plugin-sanity-image"

export default ({ heading, images }) => {
  return (
    <div>
      {heading ? (
        <div>
          <h2>{localized(heading)}</h2>
        </div>
      ) : null}
      <div style={{ marginTop: heading ? "36px" : 0, maxWidth: 800 }}>
        {
          <Image
            {...images[0]}
            style={{
              display: "block",
              // width: "100%",
              objectFit: "cover",
            }}
          />
        }
      </div>
    </div>
  )
}
