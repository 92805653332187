import React from "react"
import { localized } from "../../../utils/localized"
import Img from "gatsby-image"
import NamedAnchor from "../../NamedAnchor"

export default ({
  heading,
  subHeading,
  description,
  technicalIcons,
  anchorName,
  fullBleedImages,
}) => {
  // If we only have 1 tech icon, make it just fill up the rest of the available space.

  let iconsDisplay
  if (technicalIcons.length === 1) {
    let image = technicalIcons[0]
    iconsDisplay = (
      <div className="card card--shadow card--border-gradient flex flex-column justify-center">
        <img
          style={{ margin: "auto", padding: "25px" }}
          src={image.asset.fluid.src}
          alt="cs icon"
        />
      </div>
    )
  } else {
    // XXX horrible hacks
    const iconHeaderStyle = {
      padding: "25%",
    }
    const fullBleedHeaderStyle = {
      borderRadius: "16px",
    }
    let headerStyle = fullBleedImages ? fullBleedHeaderStyle : iconHeaderStyle
    let inner = technicalIcons.map((image, i) => (
      <div key={i} className="col-6">
        <div className="card card--shadow card--border-gradient flex flex-column justify-center">
          <header className="card__header" style={headerStyle}>
            <Img
              style={{ margin: "auto" }}
              className={fullBleedImages ? "" : "max-width-100"}
              style={fullBleedImages && { width: "110%" }}
              fluid={image.asset.fluid}
            />
          </header>
        </div>
      </div>
    ))
    iconsDisplay = <div className="grid grid-gap-lg">{inner}</div>
  }

  return (
    <>
      <NamedAnchor name={anchorName || "info"} />
      <section className="section section--program-split-screen">
        <div className="section__inner">
          <div className="grid grid-gap-md grid-gap-xxxl@md">
            <div className="col-6@sm">
              {/* Section Card */}
              <div className="card card--shadow card--border-gradient card--displayBorder@md">
                <div className="card__labelSticker" />
                <div className="card__content">
                  <div className="text-component">
                    <h2 className="">{localized(heading)}</h2>
                    <strong>
                      <p>{localized(subHeading)} </p>
                    </strong>
                    {description && <p>{localized(description)}</p>}
                  </div>
                </div>
              </div>
              {/* End Section Card */}
            </div>

            <div className="col-6@sm">{iconsDisplay}</div>
          </div>
        </div>
        <div className="section__bg flex items-center justify-end display@sm"></div>
      </section>
    </>
  )
}
