import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import CurriculumPanelConceptual from "../../conceptual/CurriculumPanel"
import CourseCard from "../../CourseCard"
import CTAPanel from "../../CTAPanel"

export default ({ heading, description, cta }) => {
  const coursePages = useStaticQuery(graphql`
    {
      allSanityPage(filter: { pageType: { eq: "course" } }) {
        nodes {
          course {
            startDates {
              startDate(formatString: "MMMM DD")
              endDate(formatString: "MMMM DD")
              content {
                en
                vi
              }
            }
            descriptionBrief {
              en
              vi
            }
            icon {
              ...SanityImageFields
            }
            title
          }
          _key
          title
          slug {
            current
          }
        }
      }
    }
  `).allSanityPage.nodes.filter(item => item.slug !== null)

  return (
    <section className="section__inner">
      <div className="flex flex-gap-md flex-column flex-row@md">
        <div className="col-4@md">
          <CurriculumPanelConceptual
            heading={heading}
            description={description}
          />
        </div>
        <div className="col-8@md flex flex-column flex-gap-md">
          <div className="flex flex-gap-sm flex-column flex-row@xs">
            {coursePages.map(cp => (
              <CourseCard
                key={cp.slug.current}
                slug={cp.slug.current}
                {...cp.course}
              />
            ))}
          </div>
          <div className="text-center">
            <CTAPanel ctas={[cta]} />
          </div>
        </div>
      </div>
    </section>
  )
}
