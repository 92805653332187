import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { localized } from "../../../utils/localized"
import Img from "gatsby-image"

export default ({ heading, description }) => {
  const employees = useStaticQuery(graphql`
    {
      allSanityEmployee(filter: { showOnSite: { eq: true } }) {
        nodes {
          name
          profilePhoto {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          jobTitle
        }
      }
    }
  `).allSanityEmployee.nodes

  return (
    <section className="section section--instructor-list overflow-visible">
      <div className="section__inner">
        <div className="grid grid-gap-md grid-gap-xxl@md">
          <div className="col-5@sm col-4@md">
            <div className="to-animate" data-animate="up" data-delay=".2">
              {/* Section Card */}
              <div className="card card--shadow card--border-gradient card--heightAuto card--displayBorder@md">
                <div className="card__content">
                  <div className="card__labelSticker" />
                  <div className="text-component">
                    <h2 className="">{localized(heading)}</h2>
                    <p>{localized(description)}</p>
                  </div>
                </div>
              </div>
              {/* End Section Card */}
            </div>
          </div>
          <div className="col-7@sm col-8@md">
            <div className="grid grid-gap-md grid-gap-lg@md">
              {employees.map(e => (
                <EmployeeCard key={e.name} {...e} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const EmployeeCard = ({ name, profilePhoto, jobTitle }) => {
  return (
    <div className="col-6 col-4@md to-animate" data-animate="up">
      {/* Profile card V2 */}
      <div className="card card--shadow card--border">
        <header className="card__header card__header--avatarHolder">
          <div className="block">
            <div>
              <Img
                style={{ borderRadius: "100%", height: "140px" }}
                fluid={profilePhoto.asset.fluid}
                alt={name}
              />
            </div>
          </div>
        </header>
        <div className="card__content">
          <div className="text-component text-center">
            <h3 className="card__title text-capitalize">{name}</h3>
            <div className="card__subtitle">{jobTitle}</div>
          </div>
        </div>
      </div>
      {/* End Profile card V2 */}
    </div>
  )
}
