import React from "react"
import parseCSSText from "../../../utils/parseCSSText"
import { buildImageObj } from "../../blog/lib/helpers"
import { imageUrlFor } from "../../blog/lib/image-url"
import * as SectionComponents from "./superSections"

function upperFirst(s) {
  return s && `${s[0].toUpperCase()}${s.slice(1)}`
}

function resolveSections(section) {
  const Section = SectionComponents[upperFirst(section._type)]
  if (Section) {
    return Section
  }
  console.error("Cant find section", section) // eslint-disable-line no-console
  return null
}

export default ({
  leftContent,
  rightContent,
  title,
  subTitle,
  ratio,
  backgroundImage,
  backgroundColor,
  invert,
  textColor,
  underlineColor,
  backgroundSize,
  css,
}) => {
  const style = css ? parseCSSText(css).style : null
  let ratioInt = ratio ? parseInt(ratio) : 50
  let leftComponents = leftContent
    ? leftContent.map(item => {
        const SectionContent = resolveSections(item)
        return <SectionContent {...item} key={item._key} />
      })
    : null

  let rightComponents = rightContent
    ? rightContent.map(item => {
        const SectionContent = resolveSections(item)
        return <SectionContent {...item} key={item._key} />
      })
    : null
  if (invert) {
    ratioInt = 100 - ratioInt
    const temp = leftComponents
    leftComponents = rightComponents
    rightComponents = temp
  }

  return (
    <section
      className="section super-section overflow-visible"
      style={{
        background: backgroundColor
          ? backgroundColor
          : backgroundImage
          ? `url(${imageUrlFor(buildImageObj(backgroundImage))
              .height(1000)
              .auto("format")
              .url()})`
          : null,
        backgroundSize: backgroundSize ? "cover" : null,
        color: textColor,
        ...style,
      }}
    >
      <div className="section__inner">
        <div>
          <div
            className={`text-center${
              underlineColor ? "" : " margin-bottom-lg"
            }`}
          >
            <SectionComponents.LocaleBlocks {...title} />
          </div>
          {underlineColor ? (
            <div
              className="margin-bottom-lg"
              style={{
                backgroundColor: underlineColor,
                width: 100,
                height: 6,
                borderRadius: 10,
                margin: "auto",
              }}
            />
          ) : null}
          {subTitle ? (
            <div className="text-center margin-bottom-xl">
              <SectionComponents.LocaleBlocks {...subTitle} />
            </div>
          ) : null}
        </div>
        {leftContent.length && rightContent.length ? (
          <div className="super-section__lr">
            <div
              style={{ flexBasis: ratioInt + "%" }}
              className="super-section__left"
            >
              {leftComponents}
            </div>
            <div
              style={{
                flexBasis: 100 - ratioInt + "%",
                display: "flex",
                width: 100 - ratioInt + "%",
                flexDirection: "column",
                justifyContent: "center",
              }}
              className="super-section__right"
            >
              {rightComponents}
            </div>
          </div>
        ) : (
          <div className="flex flex-column flex-center flex-gap-lg">
            {leftComponents}
            {rightComponents}
          </div>
        )}
      </div>
    </section>
  )
}
