import React from "react"
import { localized } from "../../../utils/localized"
import Img from "gatsby-image"
import { useIntl } from "react-intl"
import PortableText from "../../blog/portableText"

const KeywordList = obj => (
  <p style={{ color: "#f26d69" }}>{obj.keywords.join(" • ")}</p>
)

export default ({ heading, description, benefits }) => {
  const intl = useIntl()
  const localizedBlock = field => {
    if (intl.locale === "vi") {
      return field["_rawVi"]
    }
    return field["_rawEn"]
  }

  return (
    <section
      className="section section--why-us-cards to-animate"
      data-animate="right"
    >
      <div
        className={`section__inner section__inner--offsetRight ${
          benefits.length ? " benefit-padding" : ""
        }`}
        // data-theme="accent"
      >
        <div className="card__labelSticker" />
        <div className="text-component">
          <h2>{localized(heading)}</h2>
          <p>
            {description._type === "localeBlocks" ? (
              <PortableText blocks={localizedBlock(description)} />
            ) : (
              localized(description)
            )}
          </p>
        </div>
      </div>
      <div
        className={`${benefits.length ? "section__inner has-benefits" : ""}`}
      >
        <div className="grid grid-gap-md">
          {benefits.map((b, i) => (
            <ReasonCard {...b} key={i} index={"0" + (i + 1)} />
          ))}
        </div>
      </div>
    </section>
  )
}

const ReasonCard = ({ index, title, content, icon, keywords }) => {
  return (
    <div className="same-row@md">
      {/* Number card */}
      <div className="height-100%">
        <div className="card card--shadow card--border-gradient">
          <header className="card__header">
            <div className="card__headerInner">
              <span className="card__number">{index}</span>
              {icon != null ? (
                <div className="flex justify-end flex-grow margin-bottom-sm">
                  <Img
                    fluid={icon.asset.fluid}
                    alt="Inspired by Silicon Valley"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </header>
          <div className="card__content padding-top-0">
            <div className="has-padding@md">
              <div className="text-component">
                <h3 className="card__title">{localized(title)}</h3>
                {keywords ? <KeywordList keywords={keywords} /> : <></>}
                <p>{localized(content)}</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Number card */}
      </div>
    </div>
  )
}
