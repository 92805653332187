import React from "react"
import { localized } from "../../../../utils/localized"
import { useIntl } from "react-intl"
import PortableText from "../../../blog/portableText"
import parseCSSText from "../../../../utils/parseCSSText"
export default props => {
  const intl = useIntl()
  const localizedBlock = field => {
    if (intl.locale === "vi" && field["_rawVi"]) {
      return field["_rawVi"]
    }
    return field["_rawEn"]
  }
  const style = props.css ? parseCSSText(props.css).style : null

  return (
    <div
      className={`locale-blocks${props.className ? " " + props.className : ""}`}
      style={{ ...props.style, color: props.color, ...style }}
    >
      <PortableText blocks={localizedBlock(props)} />
    </div>
  )
}
