import React from "react"
import styled from "styled-components"
import { localized } from "../utils/localized"
import { imageUrlFor } from "../components/blog/lib/image-url"

//TODO(Charles): Being used by Flexible Payment Options as well; need to refactor prop names.

const CourseCard = ({
  slug,
  title,
  startDate,
  descriptionBrief,
  icon,
  buttonText = "Learn more",
}) => {
  return (
    <Card className="card--border-gradient col-6@md">
      {icon ? (
        <Icon
          src={imageUrlFor(icon)
            .width(150)
            .auto("format")}
        />
      ) : null}
      <div>
        <Title>{title}</Title>
        <Date>{startDate}</Date>
        <CourseContent>{localized(descriptionBrief)}</CourseContent>
      </div>
      <StyledButton href={slug} className="btn btn--primary">
        {buttonText}
      </StyledButton>
    </Card>
  )
}

const Card = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  background-color: white;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 200px;
`
// -2 is a magic number here, to help the bottom
// from getting cut off. See the 2rem padding
// in CourseList.

const Icon = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  max-width: 154px;
  margin-top: -2rem;
`

const Title = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
`
const Date = styled.div`
  font-size: 0.75rem;
  color: #e74742;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const CourseContent = styled.div`
  font-size: 0.75rem;
`

const StyledButton = styled.a`
  margin: 1rem 0rem;
`

export default CourseCard
