import React, { useState } from "react"
import { localized } from "../../../../utils/localized"
import { useIntl } from "react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { LocaleBlocks } from "."
import Image from "gatsby-plugin-sanity-image"
import Slider from "react-slick"
let activeIndex = 0
export default ({ testimonyList, dotColor, activeDotColor }) => {
  const intl = useIntl()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    beforeChange: (prev, next) => {
      // setActiveIndex(next)
      activeIndex = next
    },
    customPaging: (page, i) => {
      const style = {
        backgroundColor: dotColor || "rgb(168, 168, 168)",
      }
      const activeStyle = {
        backgroundColor: activeDotColor || "var(--color-primary)",
      }

      return (
        <button style={page === activeIndex ? activeStyle : style}>
          {page + 1}
        </button>
      )
    },
  }
  return (
    <div className="flex flex-center">
      <div className="carousel-person">
        <div>
          <Slider {...settings}>
            {testimonyList.map((item, index) => (
              <div
                key={item._id}
                className="flex flex-center flex-column carousel-single"
              >
                {item.rounded ? (
                  <Image
                    alt=""
                    {...item.image}
                    width={200}
                    height={200}
                    style={{
                      width: 200,
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <Image
                    alt=""
                    {...item.image}
                    width={420}
                    height={420}
                    style={{
                      width: 420,
                      borderRadius: 0,
                    }}
                  />
                )}
                <div className="carousel-person-title">
                  <div className="flex flex-center">
                    {localized(item.title)}
                    {item.linkedIn ? (
                      <>
                        <a href={item.linkedIn} _target="blank">
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "48px",
                              height: "48px",
                              borderRadius: "inherit",
                              padding: 12,
                            }}
                          />
                        </a>
                      </>
                    ) : null}
                  </div>
                </div>
                {item.subtitle ? (
                  <div className="carousel-person-subtitle">
                    {localized(item.subtitle)}
                  </div>
                ) : null}
                <LocaleBlocks
                  {...item.body}
                  className="carousel-person-description"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
