import React from "react"
import { localized } from "../../../utils/localized"
// import Img from "gatsby-image"
import { imageUrlFor } from "../../blog/lib/image-url"

// TODO needed bg job guarantee
export default ({ heading, description, companies }) => {
  return (
    <section className="section section--job-guarantee">
      <div className="bg-wrapper display@md" />
      <div className="section__inner">
        <div className="grid grid-gap-xl@md">
          <div className="col-12 col-4@md job-guarantee__left">
            <div className="padding-lg">
              <h2 className="margin-bottom-xs text-center">{localized(heading)}</h2>
              <p className="job-guarantee__text">{localized(description)}</p>
            </div>
          </div>
          <div className="col-12 col-8@md">
            <div className="">
              <div className="bg-wrapper hide@md" />
              <div className="grid grid-gap-md position-relative">
                {companies.map(c => (
                  <Company key={c.name} logo={c.logo} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Company = ({ logo }) => (
  <div className="col-6 col-3@md">
    <div className="card-client">
      <div className="card-client__image">
        <img
          src={imageUrlFor(logo)
            .width(120)
            .auto("format")
            .url()}
          alt="company"
        />
      </div>
    </div>
  </div>
)
