import React from "react"
import { localized } from "../../utils/localized"

export default ({ heading, description }) => {
  return (
    <div className="card card--shadow card--border-gradient card--displayBorder@md card--heightAuto">
      <div className="card__content has-padding@md has-no-margin@md margin-bottom-lg">
        <div className="card__labelSticker"></div>
        <div className="text-component">
          <h2 className="">{localized(heading)}</h2>
          <p>{localized(description)}</p>
        </div>
      </div>
    </div>
  )
}
