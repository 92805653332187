import React, { useEffect, useRef, useState } from "react"
import { LocaleBlocks } from "."
import * as Icons from "../../../../utils/faNonRegularIcons"
import * as RegularIcons from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default ({ items }) => {
  return (
    <div className="timeline">
      {items.map(item => (
        <MilestoneSection key={item._key} item={item} />
      ))}
    </div>
  )
}

const MilestoneSection = ({ item }) => {
  const [visible, setVisible] = useState(false)
  const ref = useRef()
  useEffect(() => {
    const current = ref.current
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setVisible(true)
          observer.unobserve(current)
        }
      },
      { threshold: 0.5 }
    )
    observer.observe(current)
    return () => observer.unobserve(current)
  }, [])
  return (
    <div
      className={`timeline-block ${
        visible ? "timeline-visible" : "timeline-invisible"
      }`}
      ref={ref}
    >
      <div
        className="timeline-line"
        style={{ backgroundColor: item.color || "var(--color-primary)" }}
      />
      <div>
        <LocaleBlocks
          {...item.heading}
          className="timeline-year"
          style={{ color: item.color || "var(--color-primary)" }}
        />
        <div className="timeline-separator">
          <div
            className="timeline-start"
            style={{ borderColor: item.color || "var(--color-primary)" }}
          />
          <div
            className="timeline-arrow"
            style={{
              borderLeftColor: item.color || "var(--color-primary)",
            }}
          />
          <div className="timeline-dots" />
          {item.icon ? (
            <div className="timeline-icon">
              <div
                className="timeline-icon-link"
                style={{
                  backgroundColor: item.color || "var(--color-primary)",
                }}
              />
              <FontAwesomeIcon
                style={{
                  width: 80,
                  height: 80,
                  border: `8px solid ${
                    item.color ? item.color : "var(--color-primary)"
                  }`,
                  borderRadius: "50%",
                  padding: 15,
                }}
                color={item.color ? item.color : "var(--color-primary)"}
                icon={
                  item.icon.name.includes("FaReg")
                    ? RegularIcons["fa" + item.icon.name.slice(5)]
                    : Icons[
                        item.icon.name[0].toLowerCase() +
                          item.icon.name.slice(1)
                      ]
                }
              />
            </div>
          ) : null}
        </div>

        <LocaleBlocks {...item.description} className="timeline-description" />
      </div>
    </div>
  )
}
