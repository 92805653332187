import React from "react"
import { localized } from "../../../utils/localized"
import YouTube from "react-youtube"

export default ({ caption, subtitle, youtubeLink }) => {
  const opts = {
    maxHeight: "432",
    maxWidth: "1200",
    width: "100%",

    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  }
  let youtubeId = youtubeLink.split("/")
  youtubeId = youtubeId[youtubeId.length - 1]

  return (
    <>
      <div className="section section--resize-l">
        <div className="section__inner">
          <div className="feature__item feature__item--content">
            <div className="text-component margin-bottom-xs">
              <h2>{localized(caption)}</h2>
            </div>
            <div className="text-component margin-bottom-xs">
              {subtitle && <p>{localized(subtitle)}</p>}
            </div>
            <div>
              <YouTube videoId={youtubeId} opts={opts} onReady={true} />;
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
