import React, { useEffect, useState } from "react"

export default ({ link }) => {
  const [isClient, setIsClient] = useState(false)
  const ref = React.useRef()

  const changeHeight = doc => {
    try {
      doc.height = doc.contentWindow.document.body.scrollHeight
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <div className="margin-bottom-xl">
      {isClient ? (
        <iframe
          ref={ref}
          src={link}
          title="embed"
          width="100%"
          scrolling="no"
          onLoad={() => changeHeight(ref.current)}
        />
      ) : null}
    </div>
  )
}
