import React from "react"
import { localized } from "../../../utils/localized"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import { useIntl } from "react-intl"
export default ({ image, title, content }) => {
  let intl = useIntl()
  const localizedBlock = field => {
    if (intl.locale === "vi") {
      return field["_rawVi"]
    }
    return field["_rawEn"]
  }
  return (
    <div className="section">
      <div className="section__inner">
        <div className="grid grid-gap-md grid-gap-lg@sm grid-gap-xxl@md">
          <div className="col-6@sm col-5@md">
            <div className="to-animate" data-animate="up" data-delay=".2">
              <div>
                <Img fluid={image.asset.fluid} />
              </div>
            </div>
          </div>
          <div className=" col-6@sm col-7@md">
            <div className="box padding-x-lg  has-no-padding@sm">
              <div className="text-component">
                <h2>{localized(title)}</h2>
                <BlockContent blocks={localizedBlock(content)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
