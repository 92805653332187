import React from "react"
import styled from "styled-components"
import { localized } from "../../../utils/localized"

import Collapsible from "react-collapsible"
import WinnerImage from "../../../images/images/winner.jpg"
import Device from "../../../utils/responsive-utils"

import NamedAnchor from "../../NamedAnchor"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const Trigger = ({ text }) => {
  return (
    <FlexContainer>
      <FontAwesomeIcon
        icon={faChevronRight}
        style={{
          width: "16px",
          height: "16px",
          marginRight: "0.5rem",
          color: "var(--color-primary)",
          transition: "transform 300ms ease-in-out 0s",
        }}
      />
      <Text>{text}</Text>
    </FlexContainer>
  )
}

export default ({ questions, heading, anchorName }) => {
  return (
    <>
      <NamedAnchor name={anchorName || "faq"} />
      <FrequentlyAskedQuestionSection>
        <h2> {localized(heading)} </h2>
        <GridContainer>
          <CollapsibleContainer>
            {questions.map((q, i) => (
              <StyledCollapsible
                key={i}
                trigger={<Trigger text={localized(q.title)} />}
              >
                <Text>{localized(q.content)}</Text>
              </StyledCollapsible>
            ))}
          </CollapsibleContainer>
          <Image src={WinnerImage} />
        </GridContainer>
      </FrequentlyAskedQuestionSection>
    </>
  )
}

// TODO - margintop is a hack here

const FrequentlyAskedQuestionSection = styled.section`
  margin-top: 6rem;
  padding: 0 2rem;
  @media ${Device.tablet} {
    padding: 0 4rem 0 4rem;
  }
  @media ${Device.laptop} {
    padding: 0 4rem 0 10rem;
  }
`

const GridContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-gap: 2rem;
  @media ${Device.tablet} {
    grid-template-columns: 1.5fr 1fr;
  }
`
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`
const Icon = styled.i`
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  color: var(--color-primary);
  transition: transform 300ms ease-in-out 0s;
`
const Text = styled.div`
  font-size: 14px;
  text-align: justify;
  line-height: 20px;
`

const CollapsibleContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 1rem;
  }
  .Collapsible__trigger {
    display: block;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .Collapsible__trigger.is-closed {
    padding: 1rem;
    cursor: pointer;
  }
  .Collapsible__trigger.is-open {
    background: var(--color-primary);
    color: white;
    padding: 1rem;
    cursor: pointer;
    transition: background 300ms ease-in-out 0s !important;
  }
  .Collapsible__trigger.is-open svg {
    color: white !important;
    transform: rotateZ(90deg);
  }
  .Collapsible__contentInner {
    padding: 1rem;
    border: var(--color-primary) 1px solid;
    border-top-color: transparent;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .Collapsible__contentOuter {
    transition: height 300ms ease-in-out 0s !important;
  }
`
const Image = styled.img``
const StyledCollapsible = styled(Collapsible)``
