import React from "react"
import { localized } from "../../../utils/localized"
import { Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import CTAPanel from "../../CTAPanel"
import { useIntl } from "react-intl"
import PortableText from "../../blog/portableText"
// import BackgroundImage from "gatsby-background-image"

export default ({
  heading,
  subtitle,
  caption,
  ctas,
  mainImage,
  backgroundColor,
  textColor,
  mainImagePosition,
  course,
}) => {
  caption = caption || { en: "", vi: "" } // TODO(Charles): this a bit messy to set a default value
  const intl = useIntl()
  const localizedBlock = field => {
    if (intl.locale === "vi") {
      return field["_rawVi"]
    }
    return field["_rawEn"]
  }
  return (
    // Changing this from BackgroundImage
    <section
      // Tag="section"
      // fluid={backgroundImage.asset.localFile.childImageSharp.fluid}
      className="sections sections-row--md"
    >
      <div
        className="hero-background"
        style={{ background: backgroundColor || "var(--color-primary)" }}
      >
        <div className="section section--resize-l align-items-center">
          <div className="section__inner padding-bottom-xl padding-top-xl">
            <div className="feature__item feature__item--content">
              {caption ? (
                <div className="feature__label margin-bottom-xs">
                  <p>{localized(caption)}</p>
                </div>
              ) : null}
              <div
                className="text-component"
                style={{ color: textColor || "white" }}
              >
                <PortableText blocks={localizedBlock(heading)} />

                {subtitle && (
                  <div
                    className="hero-subtitle"
                    style={{ color: textColor || "white" }}
                  >
                    <PortableText blocks={localizedBlock(subtitle)} />
                  </div>
                )}
              </div>
              {ctas.length ? (
                <div className="margin-top-xl">
                  <div className="flex flex-wrap flex-gap-md items-center justify-center">
                    <CTAPanel ctas={ctas} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={`section sections-row--md${
            mainImagePosition === "left" ? " section--resize-r" : ""
          }`}
        >
          <div className="feature__item feature__item--media to-animate section__inner">
            <figure>
              <Img
                fluid={mainImage.asset.localFile.childImageSharp.fluid}
                className={`heroV2-img ${mainImagePosition}`}
              />
            </figure>
          </div>

          {/* <div className="pre-header pre-header--text-center pre-header--stickyBottom@md"></div> */}
        </div>
      </div>
    </section>
  )
}
