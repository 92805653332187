import { Link } from "gatsby-plugin-intl"
import React from "react"
import { localized } from "../../../utils/localized"

const CourseCardContent = ({ item, index }) => {
  return (
    <div
      className={
        !index ? "text-center card--border-gradient card--shadow card p-1" : ""
      }
    >
      <div className="card__title">
        {item.startDate} - {item.endDate}
      </div>
      <div>{localized(item.content)}</div>
    </div>
  )
}
export default ({ heading, course }) => {
  const { startDates } = course

  return (
    <section className="section section--program-split-screen pt-1 no-link-style">
      <div className="section__inner">
        <div className="grid grid-gap-md ">
          <div className="col-12 col-4@sm text-xxl upcoming-title text-center pt-1 ">
            {localized(heading)}
          </div>
          <div className="col-12 col-8@sm ">
            <div className="grid grid-gap-md">
              {startDates.map((item, index) => {
                return (
                  <div
                    className={
                      !index
                        ? "col-5@sm align-items-center"
                        : "col-5@sm text-center align-items-center pt-1"
                    }
                  >
                    {!index ? <div className="label">Coming Soon</div> : null}
                    {item.slug ? (
                      <Link
                        to={item.slug.current && "/course/" + item.slug.current}
                      >
                        <CourseCardContent item={item} index={index} />
                      </Link>
                    ) : (
                      <CourseCardContent item={item} index={index} />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
