import React from "react"
import { localized } from "../../../utils/localized"

export default ({ highlightFeatures }) => {
  return (
    <section className="section section--program-split-screen pt-2 background-red pb-2">
      <div className="section__inner">
        <div className="grid grid-gap-md">
          {highlightFeatures &&
            highlightFeatures.map(feature => {
              return (
                <div key={feature._key} className="col-12 col-3@xs text-center">
                  <div className="figure-title">
                    {localized(feature.figure)}
                  </div>
                  <div className="subtitle">
                    {localized(feature.description)}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </section>
  )
}
