import React from "react"

import { localized } from "../../../utils/localized"
import Img from "gatsby-image"
import NamedAnchor from "../../NamedAnchor"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faLink } from "@fortawesome/free-solid-svg-icons"

export default ({ course, anchorName }) => {
  const teachers = course.teachers
  return (
    <>
      <NamedAnchor name={anchorName || "instructors"} />
      <section className="section section--profile-cards">
        <div className="section__inner">
          <div className="grid grid-gap-md grid-gap-xl@md">
            {teachers
              .sort((a, b) => (a.name > b.name) - (a.name < b.name))
              .map(t => (
                <Teacher key={t.name} {...t} />
              ))}
          </div>
        </div>
        <div className="section__bg flex items-center justify-end display@sm"></div>
      </section>
    </>
  )
}

const Teacher = ({ name, jobTitle, description, linkedIn, profilePhoto }) => {
  return (
    <div className="col-12 col-6@xs col-4@md">
      <div>
        {/* Profile card */}
        <div className="card card--heightAuto card--shadow card--border-gradient">
          <header className="card__header">
            <div>
              <Img fluid={profilePhoto.asset.fluid} />
            </div>
            <ul className="card__socialMeta">
              <li className="card__socialMetaItem">
                <a href={linkedIn} _target="blank">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{
                      background: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "48px",
                      height: "48px",
                      borderRadius: "inherit",
                      padding: "12px",
                    }}
                  />
                </a>
              </li>
            </ul>
          </header>
          <div className="card__content">
            <div className="card__labelSticker card__labelSticker--small" />
            <div className="text-component">
              <h3 className="card__title">{name}</h3>
              <span className="card__subtitle margin-bottom-md">
                {jobTitle}
              </span>
              <p>{localized(description)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
