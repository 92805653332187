import React from "react"
import Slider from "react-slick"
import { imageUrlFor } from "../../../blog/lib/image-url"

// TODO needed bg job guarantee
export default ({ heading, description, singleLine, companies, maxHeight }) => {
  maxHeight = maxHeight || 120
  if (singleLine)
    return (
      <div style={{ overflow: "hidden", width: "100%" }}>
        <div style={{ overflow: "hidden", width: "100%" }}>
          <div style={{ maxWidth: 800 }}>
            <Slider {...settings}>
              {companies.map(c => (
                <Company
                  key={c.name}
                  logo={c.logo}
                  website={c.website}
                  maxHeight={maxHeight}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    )
  return (
    <div
      className="flex flex-gap-xxl flex-wrap flex-center position-relative width-100%"
      style={{ maxWidth: 800 }}
    >
      {companies.map(c => (
        <Company
          key={c.name}
          logo={c.logo}
          website={c.website}
          maxHeight={maxHeight}
        />
      ))}
    </div>
  )
}

const Company = ({ logo, maxHeight, website, className }) => (
  <div className={className}>
    <div className="card-client">
      <a
        className="card-client__image"
        style={{ height: maxHeight, width: maxHeight }}
        href={website}
      >
        <img
          style={{ maxHeight }}
          src={imageUrlFor(logo)
            .width(maxHeight)
            .auto("format")
            .url()}
          alt="company"
        />
      </a>
    </div>
  </div>
)

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
