import React, { useRef } from "react"
import { useEffect } from "react"
import { LocaleBlocks } from "../superSections"

export const AccordionPart = ({ expand, content }) => {
  const ref = useRef()
  useEffect(() => {
    if (ref) {
      if (expand) {
        ref.current.style.height = ref.current.scrollHeight + "px"
        ref.current.style.borderBottom = "1px solid rgba(0, 0, 0, 0.2)"
      } else {
        ref.current.style.height = 0
        ref.current.style.borderBottom = "1px solid white"
      }
    }
  }, [expand])

  return (
    <div className={`accordion-item__content`} ref={ref}>
      {content.map(c => (
        <div key={c._key} className="accordion-item__content-row">
          <LocaleBlocks
            {...c.leftColumn}
            className="accordion-item__content-left"
          />
          <LocaleBlocks
            {...c.rightColumn}
            className="accordion-item__content-right"
          />
        </div>
      ))}
    </div>
  )
}
