import React from "react"
import { localized } from "../../../utils/localized"

export default ({ heading, embedCode }) => {
  return (
    <section className="section section--profile-cards">
      <div className="section__inner text-center">
        <h2 className=" margin-bottom-md margin-top-md">
          {localized(heading)}{" "}
        </h2>
        <div dangerouslySetInnerHTML={{ __html: embedCode }} />
      </div>
    </section>
  )
}
