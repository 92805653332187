import React from "react"
import { localized } from "../../../utils/localized"
import Scrollspy from "react-scrollspy"

export default ({ links }) => {
  return (
    <Scrollspy
      items={links.map(l => l.anchorName)}
      currentClassName="is-current-scrollspy"
      style={{
        position: "sticky",
        overflowX: "auto",
        whiteSpace: "nowrap",
        backgroundColor: "white",
        padding: "1rem",
        width: "100%",
        boxShadow: "0px 8px 20px rgb(0 0 0 / 10%)",
        top: "79px",
        zIndex: 4,
        textAlign: "center",
      }}
    >
      {links.map(l => (
        <li
          key={l.anchorName}
          style={{ display: "inline-block", paddingRight: "1rem" }}
        >
          <a style={{ textDecoration: "none" }} href={"#" + l.anchorName}>
            {localized(l.title)}
          </a>
        </li>
      ))}
    </Scrollspy>
  )
}
