import React, { useState } from "react"
import { Link } from "gatsby-plugin-intl"
import { localized } from "../utils/localized"
import Modal from "react-modal"
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "520px",
    height: "80%",
    overflow: "hidden",
  },
  overlay: { zIndex: 1000 },
}
const CTALink = ({ link, title, openNewTab, index, size, color }) => {
  const localizedTitle = localized(title)
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
  if (openNewTab) {
    return (
      <a
        href={link}
        className={`btn btn--primary ${size ? `cta-${size}` : ""}`}
        target="_blank"
      >
        {localizedTitle}
      </a>
    )
  }
  if (link.startsWith("http")) {
    return (
      <>
        <div
          onClick={openModal}
          className={`btn btn--primary btn-width ${size ? `cta-${size}` : ""}`}
        >
          {localizedTitle}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <iframe src={link} style={{ width: "100%", height: "100%" }}></iframe>
        </Modal>
      </>
    )
  } else {
    return (
      <Link
        to={link}
        className={`btn btn--primary js-smooth-scroll ${
          size ? `cta-${size}` : ""
        }`}
      >
        {localizedTitle}
      </Link>
    )
  }
}

export default ({ ctas }) => {
  return (
    <>
      {ctas.map((cta, idx) => (
        <CTALink key={cta.link} index={idx} {...cta} />
      ))}
    </>
  )
}
