import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { localized } from "../../../utils/localized"
import BlogCard from "../../blog/BlogCard"

export default ({ heading }) => {
  const posts = useStaticQuery(graphql`
    {
      allSanityPost(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
        limit: 3
      ) {
        edges {
          node {
            id
            publishedAt
            mainImage {
              ...SanityImageFields
            }
            title
            excerpt
            slug {
              current
            }
          }
        }
      }
    }
  `).allSanityPost.edges.map(p => p.node)

  return (
    <section className="section section--profile-cards">
      <div className="section__inner">
        <h2 className=" margin-bottom-md"> {localized(heading)} </h2>
        <div className="grid grid-gap-md grid-gap-xxl@md">
          {posts.map(p => (
            <div key={p.title} className="col-5@sm col-4@md">
              <BlogCard {...p} key={p._key} />
            </div>
          ))}
        </div>
      </div>
      <div className="section__bg flex items-center justify-end display@sm"></div>
    </section>
  )
}
