import React from "react"
import { localized } from "../../../utils/localized"
import styled from "styled-components"

export default ({ heading, jobOpenings }) => {
  return (
    <section className="section section--profile-cards">
      <div className="section__inner card__content">
        <h2 className=" margin-bottom-md"> {localized(heading)} </h2>
        <div className="grid grid-gap-md grid-gap-xl@md">
          <ul>
            {jobOpenings.map(j => (
              <JobOpeningLi key={j.id}>
                <a href={j.jdLink}>{j.title}</a>
                <JobOpeningCity>Ho Chi Minh City, Vietnam</JobOpeningCity>
              </JobOpeningLi>
            ))}
          </ul>
        </div>
      </div>
      <div className="section__bg flex items-center justify-end display@sm"></div>
    </section>
  )
}

const JobOpeningLi = styled.li`
  padding-bottom: 32px;
`

const JobOpeningCity = styled.p`
  margin-top: 5px;
  color: #32373e;
`
