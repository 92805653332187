import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { localized } from "../../../utils/localized"
import { useIntl } from "react-intl"

// TODO - move to sanity

// Code duplicated from the Teachers card (conceptual/teachers.js)
// Consider making a "card with image" or something perhaps?

export default ({ heading }) => {
  // const events = useStaticQuery(graphql`
  //   {
  //     allFbEvent {
  //       nodes {
  //         cover {
  //           source
  //         }
  //         description
  //         name
  //         start_time
  //         facebookId
  //       }
  //     }
  //   }
  // `).allFbEvent.nodes.map(e => ({
  //   img: e.cover.source,
  //   description: e.description,
  //   name: e.name,
  //   startTime: e.start_time,
  //   facebookId: e.facebookId,
  // }))

  return (
    <section className="section section--profile-cards">
      {/* <div className="section__inner">
        <h2 className=" margin-bottom-md"> {localized(heading)} </h2>
        <div className="grid grid-gap-md grid-gap-xl@md">
          {events.map(e => (
            <EventCard key={e.facebookId} {...e} />
          ))}
        </div>
      </div>
      <div className="section__bg flex items-center justify-end display@sm"></div> */}
    </section>
  )
}

const EventCard = ({ img, description, name, startTime, facebookId }) => {
  const intl = useIntl()
  const dateFormat = {
    weekday: "long",
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
  }
  const locale = intl.locale === "vi" ? "vi-VN" : "en-GB"
  // TODO - make this a more sustainable code, this is f'ing crazy
  // https://stackoverflow.com/a/49138448/396324
  // need to add a colon, otherwise safari (and all iPhones) just die
  // it's 3 after the +, since the timezone should be like hh:mm (e.g. 0700 becomes 07:00)
  const indexOfPlus = startTime.lastIndexOf("+")
  const dateObj = new Date(
    startTime.slice(0, indexOfPlus + 3) + ":" + startTime.slice(indexOfPlus + 3)
  )
  const dateStr = new Intl.DateTimeFormat(locale, dateFormat).format(dateObj)
  return (
    <div className="col-12 col-6@xs col-4@md" style={{ marginTop: "0px" }}>
      <div className="card-wrapper-100">
        {/* Profile card  - teacher cards have a 2n+2 CSS rule I want to ignore */}
        <div className="card card--shadow card--border-gradient">
          <header className="card__header">
            <div>
              <img src={img} />
            </div>
          </header>
          <div className="card__content">
            <div className="card__labelSticker card__labelSticker--small" />
            <div className="text-component">
              <h3 className="card__title">{name}</h3>
              <span className="card__subtitle margin-bottom-md">{dateStr}</span>
              <p>
                {description
                  .replace(/(?:https?|ftp):\/\/[\n\S]+/g, "")
                  .split(/[.!?]/)
                  .slice(0, 2) + "."}
                <br />
                <br />
                <a href={`http://www.facebook.com/events/${facebookId}`}>
                  {intl.formatMessage({ id: "more_info_events" })}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
