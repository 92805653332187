import React from "react"
import { localized } from "../../../utils/localized"
import Dots from "../../../utils/Dots"
import Img from "gatsby-image"
import NamedAnchor from "../../NamedAnchor"

export default ({ benefits, anchorName, heading }) => {
  return (
    <>
      <NamedAnchor name={anchorName || "benefits"} />
      <section className="sections sections-row--md flex-center flex section--learn-benefit">
        <div className="flex-center">
          <div className="section__inner">
            <div className="box has-no-padding@md">
              <div>
                <div className="text-component margin-bottom-xl">
                  <h2>{localized(heading)}</h2>
                </div>
              </div>
              <div className="grid grid-gap-lg">
                {benefits.map(b => (
                  <BenefitCard {...b} key={b.title.en} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="section__bg flex items-end display@sm">
          <Dots />
        </div>
      </section>
    </>
  )
}
// TODO: Clean this up, so much overlap with WhyChoose
// TODO: This image is f'ed up, clean this up

const BenefitCard = ({ title, content, icon }) => {
  return (
    <div className="col-4@sm">
      <div>
        <div
          className="card card--small card--border-gradient flex justify-center items-center"
          style={{ margin: "0 auto" }}
        >
          <div style={{ width: "48px" }}>
            <Img fluid={icon.asset.fluid} />
          </div>
        </div>
        <div className="text-component margin-top-md">
          <p className="margin-bottom-xs">
            <strong>{localized(title)}</strong>
          </p>
          <p>{localized(content)}</p>
        </div>
      </div>
    </div>
  )
}
