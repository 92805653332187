import React, { useRef } from "react"
import { localized } from "../../../utils/localized"
import { useIntl } from "react-intl"
import { LocaleBlocks } from "./superSections"
import { useState } from "react"
import { AccordionPart } from "./accordion/AccordionPart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"

export default ({
  condition,
  heading,
  description,

  accordions,
}) => {
  const [selected, setSelected] = useState(0)
  const [accordion1, accordion2] = accordions
  let accordion
  if (accordion2) {
    accordion = {
      rows: accordion1.content.map((item, index) => {
        return {
          left: [item.left, accordion2.content[index].left],
          description: [
            item.description,
            accordion2.content[index].description,
          ],
          right: [item.right, accordion2.content[index].right],
          content: [item.content, accordion2.content[index].content],
        }
      }),
    }
  } else {
    accordion = {
      rows: accordion1.content.map((item, index) => {
        return {
          key: item._key,
          left: [item.left, item.left],
          description: [item.description, item.description],
          right: [item.right, item.right],
          content: [item.content, item.content],
        }
      }),
    }
  }

  const [expand, setExpand] = useState(Array(accordion.rows.length).fill(false))
  return (
    <section className="section overflow-visible">
      <div className="section__inner max-width-800">
        <div className="text-center margin-bottom-lg">
          <LocaleBlocks {...heading} />
        </div>
        <div className="text-center margin-bottom-lg">
          <LocaleBlocks {...description} className="text-component" />
        </div>

        {condition ? (
          <div className="flex justify-end margin-bottom-lg">
            <LocaleBlocks {...condition} className="text-component" />
            <label class="switch margin-left-xs">
              <input
                type="checkbox"
                onChange={() => setSelected(selected ? 0 : 1)}
              />
              <span class="slider round"></span>
            </label>
          </div>
        ) : (
          ""
        )}

        <div className="accordion">
          {accordion.rows.map((item, index) => (
            <div className="accordion-item" key={item.key}>
              <div
                className="accordion-item__top"
                onClick={() => {
                  const temp = [...expand]
                  temp[index] = !expand[index]
                  setExpand(temp)
                }}
              >
                <div className="accordion-left">
                  <LocaleBlocks {...item.left[selected]} />
                </div>
                <div className="accordion-center">
                  <LocaleBlocks {...item.description[selected]} />
                </div>
                <div className="accordion-right">
                  <LocaleBlocks {...item.right[selected]} />
                  <FontAwesomeIcon
                    className="margin-left-xxs"
                    icon={expand[index] ? faChevronUp : faChevronDown}
                    size="2x"
                    color="#666666"
                  />
                </div>
              </div>
              <AccordionPart
                expand={expand[index]}
                content={item.content[selected]}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
