import React from "react"
import { localized } from "../../../utils/localized"
import { Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import CTAPanel from "../../CTAPanel"
// import BackgroundImage from "gatsby-background-image"

export default ({
  heading,
  subtitle,
  caption,
  ctas,
  mainImage,
  backgroundImage,
  hasNextClassFooter,
  course,
}) => {
  // caption = caption || { en: "CoderSchool", vi: "CoderSchool" } // TODO(Charles): this a bit messy to set a default value
  return (
    // Changing this from BackgroundImage
    <section
      // Tag="section"
      // fluid={backgroundImage.asset.localFile.childImageSharp.fluid}
      className="sections sections-row--md section--first-screen feature"
    >
      <div className="section section--resize-l">
        <div className="section__inner">
          <div className="feature__item feature__item--content">
            <div className="feature__label margin-bottom-xs">
              <p>{caption ? localized(caption) : null}</p>
            </div>
            <div className="text-component">
              <h2 className="">{localized(heading)}</h2>
              {subtitle && <p>{localized(subtitle)}</p>}
            </div>
            <div className="margin-top-xl">
              <div className="flex flex-wrap flex-gap-md items-center">
                <CTAPanel ctas={ctas} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section--resize-r section--resize-r--wider">
        <div className="feature__item feature__item--media to-animate">
          <figure>
            <Img fluid={mainImage.asset.localFile.childImageSharp.fluid} />
          </figure>
        </div>

        {/* <div className="pre-header pre-header--text-center pre-header--stickyBottom@md"></div> */}
      </div>
    </section>
  )
}
