import React, { Fragment } from "react"
import PropTypes from "prop-types"
import * as SectionComponents from "./sections"

function upperFirst(s) {
  return s && `${s[0].toUpperCase()}${s.slice(1)}`
}

function resolveSections(section) {
  const Section = SectionComponents[upperFirst(section._type)]

  if (Section) {
    return Section
  }
  console.error("Cant find section", section) // eslint-disable-line no-console
  return null
}

// The additional course prop here is pretty gross.
// I don't think wrapping this in a HOC makes any sense.
// I think the idea is that maybe, we just add some "additional data" or something
// as a prop, and it may be course, it may be whatever.
// Either that or we have to define different sections.

function RenderSections(props) {
  const { sections, course } = props

  if (!sections) {
    console.error("Missing section")
    return <div>Missing sections</div>
  }

  return (
    <Fragment>
      {sections.map(section => {
        const SectionComponent = resolveSections(section)
        if (!SectionComponent) {
          return null
        }

        return (
          <SectionComponent {...section} key={section._key} course={course} />
        )
      })}
    </Fragment>
  )
}

RenderSections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      _type: PropTypes.string,
      _key: PropTypes.string,
      section: PropTypes.instanceOf(PropTypes.object),
    })
  ),
}

export default RenderSections
