import React from "react"
import { localized } from "../../../../utils/localized"
import { useIntl } from "react-intl"
import PortableText from "../../../blog/portableText"
import Image from "gatsby-plugin-sanity-image"
import { LocaleBlocks } from "."
import * as Icons from "../../../../utils/faNonRegularIcons"
import * as RegularIcons from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default ({ heading, content, image, rounded, icon, size }) => {
  // const localizedBlock = field => {
  //   if (intl.locale === "vi") {
  //     return field["_rawVi"]
  //   }
  //   return field["_rawEn"]
  // }

  return (
    <div className="icon-paragraph">
      <div className="icon-paragraph-bg">
        {image ? (
          <Image
            {...image}
            width={80}
            height={80}
            className="icon-paragraph-image"
            style={{
              borderRadius: rounded ? "50%" : 0,
              width: 160,
              height: "auto",
              objectFit: "cover",
            }}
          />
        ) : icon ? (
          <FontAwesomeIcon
            style={{ width: size ? size : 60, height: size ? size : 60 }}
            color="var(--color-primary)"
            icon={
              icon.name.includes("FaReg")
                ? RegularIcons["fa" + icon.name.slice(5)]
                : Icons[icon.name[0].toLowerCase() + icon.name.slice(1)]
            }
          />
        ) : null}
      </div>
      <div>
        <LocaleBlocks {...heading} />
        <LocaleBlocks {...content} />
      </div>
    </div>
  )
}
