import React from "react"
import { localized } from "../../../utils/localized"
import Image from "gatsby-plugin-sanity-image"

export default ({ heading, images }) => {
  let imageSection
  if (images.length === 1) {
    let image = images[0]
    imageSection = (
      <div className="flex flex-column justify-center">
        <Image
          {...image}
          width={600}
          style={{
            borderRadius: "16px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    )
  } else {
    let inner = images.map((image, i) => (
      <div key={i} className="col-6@sm">
        <div
          className="card--shadow"
          style={{
            borderRadius: "16px",
            overflow: "hidden",
          }}
        >
          <Image
            {...image}
            className="card--shadow"
            width={300}
            style={{
              display: "block",
              width: "100%",
              height: 400,
              objectFit: "cover",
            }}
          />
        </div>
      </div>
    ))
    imageSection = <div className="grid grid-gap-lg">{inner}</div>
  }

  return (
    <div className="section overflow-visible">
      <div className="section__inner">
        <div className="grid grid-gap-md grid-gap-lg@sm grid-gap-xxl@md">
          <div className="box padding-x-lg has-no-padding@sm">
            <div className="text-component">
              <h2>{localized(heading)}</h2>
            </div>
            <div style={{ marginTop: "36px" }}>{imageSection}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
