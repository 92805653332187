import React from "react"
import { localized } from "../../../../utils/localized"
import { useIntl } from "react-intl"
import PortableText from "../../../blog/portableText"
import Image from "gatsby-plugin-sanity-image"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default ({ mentorList, column, cardSize }) => {
  const intl = useIntl()
  column = column || 3
  cardSize = cardSize || "1x"
  // const localizedBlock = field => {
  //   if (intl.locale === "vi") {
  //     return field["_rawVi"]
  //   }
  //   return field["_rawEn"]
  // }

  const ratio = (parseInt(cardSize.slice(0, 1)) - 1) / 2 + 1

  return (
    <div
      className={`mentor-list text-center mentor-list-${cardSize}`}
      style={{
        maxWidth: `calc(220px * ${column} +  var(--mentor-gap)*(${column} - 1))`,
      }}
    >
      {mentorList.map(mentor => (
        // <div
        //   key={mentor._key}
        //   style={{
        //     flexBasis: `calc(${100 / column}% - var(--mentor-gap))`,
        //     display: "flex",
        //     justifyContent: "center",
        //   }}
        // >
        <div className="mentor-card" key={mentor._id}>
          <Image
            alt=""
            {...mentor.profilePhoto}
            width={160}
            height={160}
            style={{
              marginBottom: 10,
              width: "80px",
              borderRadius: "50%",
            }}
          />
          <div className="mentor-name">
            {mentor.name}{" "}
            {mentor.linkedIn ? (
              <>
                <a
                  href={mentor.linkedIn}
                  _target="blank"
                  className="mentor-linkedIn"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "48px",
                      height: "48px",
                      borderRadius: "inherit",
                      padding: 12,
                    }}
                  />
                </a>
              </>
            ) : null}
          </div>
          <div className="mentor-job">{mentor.jobTitle}</div>
          {mentor.companyPhoto ? (
            <Image
              alt=""
              {...mentor.companyPhoto}
              className="mentor-company"
              width={160 * ratio}
              style={{
                width: 80 * ratio,
                height: 30 * ratio,
                objectFit: "contain",
              }}
            />
          ) : null}
        </div>
        // </div>
      ))}
    </div>
  )
}
