import React from "react"
import WhyChoose from "./WhyChoose"
import NamedAnchor from "../../NamedAnchor"

export default ({ heading, description, course, anchorName }) => {
  return (
    <>
      <NamedAnchor name={anchorName || "curriculum-detail"} />
      {course ? (
        <WhyChoose
          heading={heading}
          description={description}
          benefits={course.curriculumModules}
        />
      ) : null}
    </>
  )
}
