import React from "react"
import Image from "gatsby-plugin-sanity-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { cloneDeep } from "lodash"

export default ({ employeeList }) => {
  if (!employeeList.every(item => !!item.row))
    return (
      <div className={`employee-list text-center`}>
        {employeeList.map(employee => (
          <div
            className="employee-card"
            key={employee._id}
            style={{
              width: 250,
              height: 250,
            }}
          >
            <Image
              {...employee.profilePhoto}
              className="employee-photo"
              alt=""
              width={250}
              height={250}
              style={{
                marginBottom: 10,
              }}
            />
            <div className="employee-text">
              <div className="employee-name">
                {employee.name}
                <a href={employee.linkedIn} _target="blank">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 18,
                      height: 18,
                      borderRadius: "inherit",
                      marginLeft: 6,
                    }}
                  />
                </a>
              </div>
              <div className="employee-job">{employee.jobTitle}</div>
            </div>
          </div>
        ))}
      </div>
    )

  const newList = cloneDeep(employeeList)
  newList.sort((a, b) => a.row || 1 - b.row || 1)
  let previousRow = 1
  let groupedByRow = [[newList[0]]]
  for (let i = 1; i < newList.length; i++) {
    if (previousRow === newList[i].row) {
      groupedByRow[groupedByRow.length - 1].push(newList[i])
    } else {
      groupedByRow.push([newList[i]])
      previousRow++
    }
  }

  return groupedByRow.map((item, index) => (
    <div key={index} className={`employee-list text-center`}>
      {item.map(employee => (
        <div
          className="employee-card"
          key={employee._id}
          style={{
            width: 250,
            height: 250,
          }}
        >
          <Image
            {...employee.profilePhoto}
            className="employee-photo"
            alt=""
            width={250}
            height={250}
            style={{
              marginBottom: 10,
            }}
          />
          <div className="employee-text">
            <div className="employee-name">
              {employee.name}
              <a href={employee.linkedIn} _target="blank">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 18,
                    height: 18,
                    borderRadius: "inherit",
                    marginLeft: 6,
                  }}
                />
              </a>
            </div>
            <div className="employee-job">{employee.jobTitle}</div>
          </div>
        </div>
      ))}
    </div>
  ))
}
