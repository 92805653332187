import React, { useRef } from "react"
import { localized } from "../../../utils/localized"
import { useIntl } from "react-intl"
import { LocaleBlocks } from "./superSections"
import Image from "gatsby-plugin-sanity-image"
import YouTube from "react-youtube"
import frame from "../../../images/images/laptop.png"

export default ({
  profilePic,
  heading,
  name,
  subtitle,
  description,
  backgroundColor,
  textColor,
  youTubeId,
  pic1,
  pic2,
  pic3,
}) => {
  return (
    <div
      className="section overflow-visible demo-project padding-top-xl padding-bottom-md"
      style={{ backgroundColor, color: textColor }}
    >
      <div className="section__inner">
        <div className="text-center margin-bottom-lg">
          <h2 className="">{localized(heading)}</h2>
        </div>
        <div className="text-center margin-bottom-lg demo-project-description">
          <LocaleBlocks
            {...description}
            className={backgroundColor ? "link-contrast" : ""}
          />
        </div>
        <div className="demo-project-content margin-bottom-xl">
          <div className="demo-project-profile-img">
            {
              <Image
                alt=""
                {...profilePic}
                style={{
                  display: "block",
                  // width: "100%"
                  boxShadow: `0 0 0 1px ${
                    textColor ? textColor : "var(--color-primary)"
                  },-20px 20px 0 -7px ${
                    backgroundColor ? backgroundColor : "white"
                  }, -20px 20px ${
                    textColor ? textColor : "var(--color-primary)"
                  }`,
                  width: 250,
                  height: 350,
                  objectFit: "cover",
                }}
              />
            }
            <h3 className="demo-project-name margin-top-md margin-bottom-xxs">
              {localized(name)}
            </h3>
            {subtitle ? (
              <LocaleBlocks
                {...subtitle}
                className={backgroundColor ? "link-contrast" : ""}
              />
            ) : null}
          </div>
          <div className="demo-project-description-wrapper">
            {youTubeId ? (
              <div className="demo-project-video">
                <img src={frame} className="demo-project-frame" alt="" />
                <YouTube
                  videoId={youTubeId}
                  className="demo-project-youtube"
                  containerClassName="demo-project-youtube-wrapper"
                />
              </div>
            ) : (
              <div className="demo-project-pic-group">
                <div className="grid-row-span-2 ">
                  {
                    <Image
                      alt=""
                      {...pic1}
                      style={{
                        display: "block",
                        // width: "100%"
                        height: "100%",
                        objectFit: "cover",
                        boxShadow: `0 0 0 1px ${
                          textColor ? textColor : "var(--color-primary)"
                        }`,
                      }}
                    />
                  }
                </div>
                <div className="">
                  {
                    <Image
                      alt=""
                      {...pic2}
                      style={{
                        display: "block",
                        width: "100%",
                        objectFit: "cover",
                        boxShadow: `0 0 0 1px ${
                          textColor ? textColor : "var(--color-primary)"
                        }`,
                      }}
                    />
                  }
                </div>
                <div className="">
                  {
                    <Image
                      alt=""
                      {...pic3}
                      style={{
                        display: "block",
                        width: "100%",
                        objectFit: "cover",
                        boxShadow: `0 0 0 1px ${
                          textColor ? textColor : "var(--color-primary)"
                        }`,
                      }}
                    />
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
