import React from "react"
import Image from "gatsby-plugin-sanity-image"
import { LocaleBlocks } from "."
import { useIntl } from "gatsby-plugin-intl"

export default ({ list }) => {
  const intl = useIntl()

  return (
    <div className={`news-list text-center`}>
      {list.map(card => (
        <div
          className="news-card"
          key={card._key}
          style={{
            maxWidth: 400,
          }}
        >
          <Image
            {...card.image}
            alt=""
            width={300}
            height={150}
            style={{
              marginBottom: 10,
            }}
          />

          <div className="news-text">
            <LocaleBlocks {...card.title} />
            <LocaleBlocks {...card.subtitle} />
            <div
              className="margin-bottom-sm"
              style={{
                backgroundColor: "var(--color-primary)",
                width: 80,
                height: 6,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            />
            <LocaleBlocks {...card.description} className="margin-bottom-sm" />
            <a
              className="news-read-more margin-top-auto te block text-right"
              target="_blank"
              href={card.website}
            >
              {intl.formatMessage({ id: "read_more" })}
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}
