import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { localized } from "../../../utils/localized"
import NamedAnchor from "../../NamedAnchor"

export default ({ paymentOptions, heading, description, anchorName }) => {
  let paymentTypes = paymentOptions.filter(
    (option, index, self) =>
      index === self.findIndex(t => t?.type?.en === option?.type?.en)
  )
  paymentTypes = paymentTypes.map(option => option.type)
  return (
    <>
      <NamedAnchor name={anchorName || "payment"} />
      <section className="section section--payment-options pb-2">
        <div className="section__inner">
          <div className="grid grid-gap-md grid-gap-xxl@md">
            <div className="col-12 col-5@md section-title">
              <div className="box has-no-padding@md">
                <div>
                  <div className="text-component">
                    <h2>{localized(heading)}</h2>
                    <p>{localized(description)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-7@md">
              <div className="to-animate" data-animate="up">
                <div className="grid grid-gap-xl  grid-gap-md@md">
                  {paymentTypes.map((type, i) => (
                    <PaymentOption
                      key={i}
                      type={type}
                      options={paymentOptions}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const PaymentOption = ({ options, type }) => {
  const intl = useIntl()
  return (
    <>
      {type ? (
        <div className="justify-center mb-0">
          <div className="payment-type-title">{localized(type)}</div>
        </div>
      ) : null}

      {/* Course card */}

      {options.map((option, index) => {
        if (localized(option.type) === localized(type)) {
          return (
            <div
              className={`col-12 ${index !== options.length - 1 ? "mb-2" : ""}`}
              key={option.type}
            >
              <div
                className="card card--border-gradient"
                style={{ height: "auto" }}
              >
                <div className="card__content">
                  <div className="text-component text-center">
                    <div className="text-uppercase price-title margin-bottom-xxs">
                      {option.from ? (
                        <span className="text-xs margin-right-sm">
                          {intl.formatMessage({ id: "from" })}
                        </span>
                      ) : (
                        ""
                      )}
                      {localized(option.amount)} VND
                    </div>
                    <div className="flex flex-gap-xxs flex-column margin-bottom-sm">
                      <div className="card__title margin-bottom-sm">
                        {localized(option.course)}
                      </div>
                      <div>{localized(option.description)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}

      {/* End Course card */}
    </>
  )
}
