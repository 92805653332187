import React from "react"
import { localized } from "../../../utils/localized"
import NamedAnchor from "../../NamedAnchor"

// TODO - Refactor some of this stuff.
import { buildImageObj } from "../../blog/lib/helpers"
import { imageUrlFor } from "../../blog/lib/image-url"
import GenericCard from "../../good/GenericCard"

export default ({ heading, cards, anchorName }) => {
  return (
    <>
      <NamedAnchor name={anchorName || "instructors"} />
      <section className="section section--profile-cards">
        <div className="section__inner">
          <h2 className=" margin-bottom-md"> {localized(heading)} </h2>
          <div className="grid grid-gap-md grid-gap-xxl@md">
            {cards.map(i => (
              <div className="col-5@sm col-4@md">
                <GenericCard
                  title={localized(i.title)}
                  description={localized(i.description)}
                  imgSrc={imageUrlFor(buildImageObj(i.mainImage))
                    .width(800)
                    .height(400)
                    .auto("format")
                    .url()}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
