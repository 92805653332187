import React from "react"
import CTAPanel from "../../CTAPanel"
import Dots from "../../../utils/Dots"
import { localized } from "../../../utils/localized"

export default ({ learningOptions, heading, ctas }) => {
  return (
    <section className="sections sections-row--md section--learn-benefit">
      <div className="section">
        <div className="section__inner">
          <div className="box has-no-padding@md">
            <div>
              <div className="text-component margin-bottom-xl">
                <h2>{localized(heading)}</h2>
              </div>
            </div>
            <div className="grid grid-gap-lg">
              {learningOptions.map(p => (
                <OptionCard {...p} key={p.title.en} ctas={ctas} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="section__bg flex items-end display@sm">
        <Dots />
      </div>
    </section>
  )
}
// TODO: Clean this up, so much overlap with WhyChoose
// TODO: This image is f'ed up, clean this up

const OptionCard = ({ title, type, time, ctas }) => {
  const localTime = localized(time)
  const timeArray = localTime && localTime.split(",")

  return (
    <div className="col-4@sm mb-3">
      <div className="card card--shadow card--border-gradient card--heightAuto">
        <div className="card__content">
          <div className="card__labelSticker" />
          <div className="text-component mb-3 mt-3 ">
            <h2 className=" text-center">{localized(title)}</h2>
            <div className="text-center class-type">{localized(type)}</div>
            <div className="mt-3">
              {timeArray &&
                timeArray.map(time => {
                  return (
                    <div className="text-center time-info" key={time}>
                      {time}
                    </div>
                  )
                })}
            </div>
            <div className="margin-top-xl">
              <div className="flex flex-wrap flex-gap-md items-center justify-center">
                <CTAPanel ctas={ctas} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
