import React from "react"
import { localized } from "../../../../utils/localized"
import { useIntl } from "react-intl"
import PortableText from "../../../blog/portableText"
import { LocaleBlocks } from "."
import Image from "gatsby-plugin-sanity-image"

export default ({ content, title, image }) => {
  const intl = useIntl()
  // const localizedBlock = field => {
  //   if (intl.locale === "vi") {
  //     return field["_rawVi"]
  //   }
  //   return field["_rawEn"]
  // }

  return (
    <div
      className="flex flex-column flex-center"
      style={{ maxWidth: 300, fontSize: 14 }}
    >
      <Image
        {...image}
        width={400}
        height={400}
        style={{
          marginBottom: 20,
          width: "200px",
          borderRadius: "50%",
        }}
      />
      <LocaleBlocks {...content} />
    </div>
  )
}
