import React from "react"
import CTAPanel from "../../CTAPanel"

export default ({ ctas }) => {
  return (
    <div
      className={`flex flex-wrap flex-gap-md items-center cta`}
      style={{
        justifyContent: "center",
      }}
    >
      <CTAPanel ctas={ctas} />
    </div>
  )
}
