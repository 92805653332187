import React from "react"
import Dots from "../../../utils/Dots"
import Img from "gatsby-image"
import { localized } from "../../../utils/localized"
import NamedAnchor from "../../NamedAnchor"

export default ({ graduateProjects, heading, anchorName }) => {
  return (
    <>
      <NamedAnchor name={anchorName || "graduate-projects"} />
      <section className="sections sections-row--md section--learn-benefit">
        <div className="section">
          <div className="section__inner">
            <div className="box has-no-padding@md">
              <div>
                <div className="text-component margin-bottom-xl">
                  <h2>{localized(heading)}</h2>
                </div>
              </div>
              <div className="grid grid-gap-lg align-items-stretch">
                {graduateProjects.map(p => (
                  <ProjectCard {...p} key={p.title.en} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="section__bg flex items-end display@sm">
          <Dots />
        </div>
      </section>
    </>
  )
}

const ProjectCard = ({ content, picture, productionLink, title }) => {
  return (
    <div className="col-4@sm mb-3">
      <div className="card--shadow card card--border-gradient">
        <div className="flex justify-center items-center">
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              borderTopRightRadius: "0.5em",
              borderTopLeftRadius: "0.5em",
            }}
          >
            <Img fluid={picture.asset.fluid} />
          </div>
        </div>
        <div className="text-component margin-top-md padding-lg">
          <p className="margin-bottom-xs text-center">
            <a href={productionLink} target="__blank">
              <strong>{localized(title)}</strong>
            </a>
          </p>
          <p>{localized(content)}</p>
        </div>
      </div>
    </div>
  )
}
