import React, { useState, useEffect } from "react"
import { localized } from "../../../utils/localized"
// import JotformEmbed from "react-jotform-embed"
import PortableText from "../../blog/portableText"
import { useIntl } from "react-intl"
// import Img from "gatsby-image"
import Image from "gatsby-plugin-sanity-image"
import quote from "../../../images/images/quote.png"
import { useWindowSize } from "../../../utils/useWindowSize"

export default ({ heading, pipedriveLink, testimony }) => {
  const [isClient, setIsClient] = useState(false)
  const size = useWindowSize()
  const ref = React.useRef()
  const titleRef = React.useRef()
  const intl = useIntl()
  const cardHeight = ref?.current?.offsetHeight
  const titleHeight = titleRef?.current?.offsetHeight
  let scaleRatio =
    (size.height - 80 - 72 - titleHeight - 30 * 2) / // total available height (before scroll): vh - navbar - testimony-desktop margin - title height - title margin bottom
    (cardHeight + 245) // total height of testimony pic + card
  if (scaleRatio > 1) scaleRatio = 1
  useEffect(() => {
    setIsClient(true)
  }, [])
  useEffect(() => {
    if (isClient) {
      const myScript = require("../../../scripts/pipedrive.loader")
    }
  }, [isClient])
  if (testimony)
    return (
      <div className="section__inner text-center margin-bottom-xl max-width-xl">
        <div style={{ display: "flex" }}>
          <div className="testimony-desktop">
            <div
              style={{
                position: "sticky",
                top: 80,
                overflow: "hidden",
                padding: 10,
                // set a fixed height due to the scaling of the (testimony card + pic) below, i.e. after the testimony is scaled down there will be some empty space at the bottom, and the sticky effect won't work correctly
                height:
                  titleHeight +
                  30 + // margin between title and testimony pic
                  20 + // bottom space to show the shadow effect
                  (cardHeight + 245) * scaleRatio, // original testimony pic + card height * scale ratio
              }}
            >
              <div
                ref={titleRef}
                className={
                  cardHeight ? "testimony--loaded" : "testimony--unloaded"
                }
              >
                <h3 className="text-uppercase color-primary">
                  {localized(testimony.title)}
                </h3>
                {localized(testimony.subtitle) ? (
                  <h4>- {localized(testimony.subtitle)} -</h4>
                ) : null}
              </div>
              <div
                className={
                  cardHeight ? "testimony--loaded" : "testimony--unloaded"
                }
                style={{
                  position: "relative",
                  marginTop: 30,
                  height: cardHeight ? cardHeight + 245 : 0,
                  transformOrigin: "top right",
                  // scale to deal with smaller desktop screens, to display the full testimony part on first load
                  transform: `scale(${scaleRatio})`,
                }}
              >
                <div>
                  <Image
                    className="testimony-pic"
                    alt=""
                    {...testimony.image}
                    width={350}
                    height={400}
                  />
                </div>
                <div className="testimony-card card--light-shadow" ref={ref}>
                  <div className="testimony-quote">
                    <img alt="" src={quote} width={90} />
                  </div>
                  <div className="testimony-text">
                    {intl.locale === "en" ? (
                      <PortableText blocks={testimony.body._rawEn} />
                    ) : (
                      <PortableText blocks={testimony.body._rawVi} />
                    )}
                    <div>
                      <div className="separator"></div>
                    </div>
                    <div className="testimony-sig">
                      {localized(testimony.signature)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            {isClient ? (
              <div
                className="pipedriveWebForms"
                data-pd-webforms={pipedriveLink}
              ></div>
            ) : null}
          </div>
        </div>
      </div>
    )
  return (
    <div className="section__inner text-center margin-bottom-xl">
      <h2 className=" margin-bottom-md margin-top-xl">{localized(heading)}</h2>
      {isClient ? (
        <div
          className="pipedriveWebForms"
          data-pd-webforms={pipedriveLink}
        ></div>
      ) : null}
    </div>
  )
}
