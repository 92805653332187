import React from "react"
import { localized } from "../../../utils/localized"
import BlockContent from "@sanity/block-content-to-react"
import { useIntl } from "react-intl"
export default ({ heading, description }) => {
  return (
    <div className="section">
      <div className="section__inner">
        <div className="grid grid-gap-md grid-gap-lg@sm grid-gap-xxl@md">
          <div className="box padding-x-lg  has-no-padding@sm">
            <div className="text-component">
              <h2>{localized(heading)}</h2>
              <p>{localized(description)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
