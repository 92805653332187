import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "react-intl"
import ModalVideo from "react-modal-video"
import Img from "gatsby-image"
import playIconWhite from "../../../images/icons/play-icon-white.png"
import styled from "styled-components"

export default ({ numVideos }) => {
  const intl = useIntl()
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [videoId, setVideoId] = useState()
  // Find all videos from our YouTube Channel that have the word "Students" in the title
  // Sort them by published date, descending (newest first)
  // Limit to 5, in the future let's add a "see more" button and all that
  // or the last one should just be a "go see youtube"
  const videos = useStaticQuery(graphql`
    {
      allYoutubeVideo(
        sort: { fields: [publishedAt], order: DESC }
        filter: { description: { regex: "/#CSstudent/" } }
        limit: 6
      ) {
        edges {
          node {
            description
            title
            videoId
            localThumbnail {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
    .allYoutubeVideo.edges.map(e => ({ ...e.node }))
    .filter((item, index) => index < numVideos)
  return (
    <section className="section section--profile-cards">
      <div className="section__inner">
        <h2 className=" margin-bottom-md">
          {intl.formatMessage({ id: "hear_from_students" })}{" "}
        </h2>
        <div className="grid grid-gap-md grid-gap-xl@md">
          {videos.map(v => (
            <VideoCard
              key={v.videoId}
              {...v}
              setVideoId={setVideoId}
              setIsVideoOpen={setIsVideoOpen}
            />
          ))}
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        isOpen={isVideoOpen}
        videoId={videoId}
        onClose={() => setIsVideoOpen(false)}
      />
    </section>
  )
}

const VideoCard = ({
  title,
  localThumbnail,
  videoId,
  description,
  setVideoId,
  setIsVideoOpen,
}) => {
  const intl = useIntl()
  const parsedDescription = description
    .split(".")[0]
    .replace("#CSstudent", "")
    .trim()
  return (
    <div className="col-12 col-6@xs col-4@md" style={{ marginTop: "0px" }}>
      <div className="card-wrapper-100">
        {/* Profile card  - teacher cards have a 2n+2 CSS rule I want to ignore */}
        <div className="card card--shadow card--border-gradient">
          <header
            className="card__header"
            style={{ position: "relative", cursor: "pointer" }}
            onClick={() => {
              setVideoId(videoId)
              setIsVideoOpen(true)
            }}
          >
            <Img fluid={localThumbnail.childImageSharp.fluid} />
            <PlayIcon src={playIconWhite} />
          </header>
          <div className="card__content">
            <div className="text-component">
              <h3 className="card__title">{title}</h3>
              <p>
                {parsedDescription}
                {parsedDescription[parsedDescription.length - 1] !== "!"
                  ? "."
                  : ""}
                <br />
                <br />
                <a
                  style={{ display: "block", textAlign: "left" }}
                  href={`http://www.youtube.com/coderschool`}
                >
                  {intl.formatMessage({ id: "youtube_page" })}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PlayIcon = styled.img`
  position: absolute;
  height: 50px;
  width: 50px !important;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
`
